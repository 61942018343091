import { defineStore } from "pinia";
import PaginationType from "@/core/types/misc/Pagination";
import ApiService from "@/core/services/ApiService";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { FilterData } from "@/core/types/misc/FilterData";
import { ApiResponse, Meta } from "@/core/types/misc/ApiResponse";
import { BlkWrapper } from "@/core/types/gws-master/master/blk/BlkWrapper";
import { ListItem } from "@/core/types/gws-ap2tki/typecert/ListItem";
import { MasterFacilityList } from "@/core/types/gws-blk/MasterFacilityList";
import { useAuthenticationStore } from "../../application/useAuthenticationStore";
import { Each } from "@/core/types/gws-blk/FacilityFormData";
import { FacilityListItem } from "@/core/types/gws-blk/FacilityListItem";

const authStore = useAuthenticationStore();
export const useBlkFacilitiesStore = defineStore({
  id: "blkFacilitiesStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFilterData: {
        page: 1,
        limit: 10,
        dir: "DESC",
        sort: "created_at",
      } as FilterData,
      statePagination: new PaginationType(),
      stateDisplayData: [] as FacilityListItem[],
      statePaginationLoading: false,
      stateMasterFacility: [] as MasterFacilityList[],
      stateError: false,
      stateErrors: [] as string[],
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    displayData(state) {
      return state.stateDisplayData;
    },
    masterFacility(state) {
      return state.stateMasterFacility;
    },
    error: (state) => {
      return state.stateError;
    },
    errors: (state) => {
      return state.stateErrors;
    },
  },
  actions: {
    async getData(isPageRefresh = true) {
      const params = {
        page: this.stateFilterData.page.toString() || "",
        limit: this.stateFilterData.limit.toString() || "",
        dir: this.stateFilterData.dir || "",
        sort: this.stateFilterData.sort || "",
      };
      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<FacilityListItem[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `blk/${authStore.user.org_id}/facility-blk?${new URLSearchParams(
              params
            ).toString()}`
          );
        this.stateDisplayData = response.data?.data;
        this.stateMasterFacility.map((el) => {
          const filtered = this.stateDisplayData.filter(
            (e) => e.master_facility_id === el.id
          );
          if (filtered) {
            el.file = filtered[0].file;
          }
        });

        this.setTotalPage(response.data.meta.pagination.count);
      } catch (error) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
    async getLookupMasterFacility() {
      const params = {
        page: this.stateFilterData.page.toString() || "",
        limit: this.stateFilterData.limit.toString() || "",
        dir: this.stateFilterData.dir || "",
        sort: this.stateFilterData.sort || "",
      };
      try {
        const response: AxiosResponse<ApiResponse<MasterFacilityList[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `master-facility?${new URLSearchParams(params).toString()}`
          );
        this.stateMasterFacility = response.data?.data;
      } catch (error) {
        console.log(error);
      }
    },
    async updateFacility() {
      this.stateIsLoading = true;
      try {
        const facilities = [] as Each[];
        this.stateMasterFacility.forEach((el) => {
          if (el.file) {
            facilities.push({
              id: el.id,
              file: el.file,
            });
          }
        });
        const payload = {
          facilites: facilities,
        };
        const response: AxiosResponse<ApiResponse<any>> = await ApiService.post(
          `${process.env.VUE_APP_GWS_API_BASE_URL}/blk/${authStore.user.org_id}/facility-blk`,
          payload as AxiosRequestConfig
        );
        if (response.data) {
          this.stateError = false;
        } else {
          this.stateError = true;
        }
      } catch (error: any) {
        const metaResp = error.response.data.meta as Meta;
        metaResp.errors[0].forEach((err) => {
          this.stateErrors.push(err.message);
        });
        this.stateError = true;
      }
      this.stateIsLoading = false;
    },
    async setPage(newPage: number) {
      this.statePagination.handleCurrentPageChange(newPage);
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData();
    },
    async setPageSize(size: number) {
      this.stateFilterData.limit = size;
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData(true);
    },
    setTotalPage(totalPage: number) {
      this.statePagination.totalPage = totalPage;
      this.statePagination.getPaginationStartIndex();
      this.statePagination.getPaginationLastIndex();
    },
  },
});
