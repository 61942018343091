import { defineStore } from "pinia";
import PaginationType from "@/core/types/misc/Pagination";
import { useAuthenticationStore } from "../../application/useAuthenticationStore";
import { FilterData } from "@/core/types/misc/FilterData";
import { InstructorListItem as ListItem } from "@/core/types/gws-blk/InstructorListItem";
import { AxiosResponse } from "axios";
import { ApiResponse } from "@/core/types/misc/ApiResponse";
import ApiService from "@/core/services/ApiService";

const authStore = useAuthenticationStore();
export const useBlkInstructorListStore = defineStore({
  id: "blkInstructorListStore",
  state: () => {
    return {
      stateIsLoading: false,
      stateFilterData: {
        page: 1,
        limit: 10,
        dir: "DESC",
        sort: "created_at",
      } as FilterData,
      statePagination: new PaginationType(),
      stateDisplayData: [] as ListItem[],
      statePaginationLoading: false,
    };
  },
  getters: {
    loading(state) {
      return state.stateIsLoading;
    },
    displayData(state) {
      return state.stateDisplayData;
    },
  },
  actions: {
    async getData(isPageRefresh = true) {
      this.stateIsLoading = true;
      authStore.getUserInfo();
      const params = {
        page: this.stateFilterData.page.toString() || "",
        limit: this.stateFilterData.limit.toString() || "",
        dir: this.stateFilterData.dir || "",
        sort: this.stateFilterData.sort || "",
      };
      try {
        if (isPageRefresh) this.stateIsLoading = true;
        const response: AxiosResponse<ApiResponse<ListItem[]>> =
          await ApiService.get(
            `${process.env.VUE_APP_GWS_API_BASE_URL}`,
            `blk/${authStore.user.org_id}/instructor-blk?${new URLSearchParams(
              params
            ).toString()}`
          );
        this.stateDisplayData = response.data?.data;
        this.setTotalPage(response.data.meta.pagination.count);
      } catch (error) {
        console.log(error);
      }
      this.stateIsLoading = false;
    },
    async setPage(newPage: number) {
      this.statePagination.handleCurrentPageChange(newPage);
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData();
    },
    async setPageSize(size: number) {
      this.stateFilterData.limit = size;
      this.statePagination.totalPageSize = this.stateFilterData.limit;
      this.stateFilterData.page = this.statePagination.currentPage;
      await this.getData(true);
    },
    setTotalPage(totalPage: number) {
      this.statePagination.totalPage = totalPage;
      this.statePagination.getPaginationStartIndex();
      this.statePagination.getPaginationLastIndex();
    },
  },
});
